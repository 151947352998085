import { processEnv } from '~/utils/env'

export type ClubConfig = {
  aboutPageEnabled: boolean
  redirectHeroToLandingEnabled: boolean
  sideEventListingPageEnabled: boolean
  careerPageEnabled: boolean
  chmEnabled: boolean
  blogEnabled: boolean
  blikEnabled: boolean
  d2dEnabled: boolean
  extraMarketingConsentEnabled: boolean
  footerAwardsEnabled: boolean
  otherFestivalsEnabled: boolean
  restaurantsListingEnabled: boolean
  logoCentered: string
  logoLeft: string
}

export const CLUB_CONFIG: ClubConfig = {
  aboutPageEnabled: true,
  careerPageEnabled: true,
  blogEnabled: true,
  chmEnabled: true,
  blikEnabled: true,
  d2dEnabled: true,
  extraMarketingConsentEnabled: true,
  footerAwardsEnabled: true,
  redirectHeroToLandingEnabled: false,
  sideEventListingPageEnabled: true,
  restaurantsListingEnabled: true,
  otherFestivalsEnabled: true,
  logoCentered: '/assets/logo/logo-club.svg',
  logoLeft: '/assets/logo/logo-club.svg',
}

export const getClubConfig = () => {
  return CLUB_CONFIG
}

export const redirectIfFeatureDisabledInConfig = {
  notFound: true as const,
}

export const isDemo = processEnv.NEXT_ENV === 'demo'
